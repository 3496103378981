import React from 'react'
import CardContainer from '../Common/CardContainer';


const AppUserComponent = () => {

  return (   
          <CardContainer/>
  )
}

export default AppUserComponent